import * as React from 'react';
import {NavLink, Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import {
    AppBar, Avatar,
    Button,
    Divider, Drawer, Fab, Fade,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Slide,
    Toolbar,
    Typography, useScrollTrigger
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const drawerWidth = 240;

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        // target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}
const d = new Date();
let year = d.getFullYear();
export default function RootLayout(props){

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ my: 2, color: '#055282' }}>
                <IconButton>
                    <Avatar alt="" src="https://i.ibb.co/c6FMVC4/image.jpg" />
                </IconButton>
            </Typography>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/'>О нас</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/news'>Новости</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/organizers'>Организаторы</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/graphic'>График</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/register'>Участвовать</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/login'>Войти</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton variant="outlined" sx={{ color: '#1d73dc', textAlign: 'center' }}>
                        <ListItemText><Typography variant={'h6'} sx={{color:'primary.main'}}><NavLink to='/help'>Помощь</NavLink></Typography></ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    return(
        <React.Fragment>
        <div className='root-layout' id="back-to-top-anchor">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                sx={{ display: 'flex' }}
            >
                <HideOnScroll {...props}>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Ин & Проф
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/'>О нас</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/news'>Новости</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/organizers'>Организаторы</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/graphic'>График</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/register'>Участвовать</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/login'>Войти</NavLink></h1>
                        </Button>
                        <Button sx={{ color: '#fff' }}>
                            <h1><NavLink to='/help'>Помощь</NavLink></h1>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
                </HideOnScroll>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    sx={{ display: 'flex' }}
                >
                    <main>
                        <Outlet />
                    </main>
                </Box>
            </Box>

            </Box>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </div>
            </React.Fragment>
    )
}
