import {useState, useEffect} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../utils/firebase/firebase-utils";
import {
    Container,
    Grid,
    Typography,
    Button,
    CardMedia,
    CardContent,
    CardActions,
    Card,
    CardHeader,
    Avatar,
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import './news.css'
import {red} from "@mui/material/colors";



export default function News() {
    const [news, setNews] = useState([])
    const newsCollectionRef = collection(db, 'news')
    const getNews = async() => {
        const data = await getDocs(newsCollectionRef)
        // console.log(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        setNews(data.docs.map((doc) => ({...doc.data(), id: doc.id})))

    }
    useEffect(() => {

        getNews().then(data => {
            console.log('News data fetched')
        })
    }, [])
    // console.log(news)
    const renderNews = news.map((item) => {
        const {description, link, picture, title, text, date} = item
        return <Grid item className='marginGrid' key={link} xs={12} md={6} lg={4} >
            <Card sx={{ maxWidth: 400 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: red[500]}} aria-label="Donald">
                            D
                        </Avatar>
                    }
                    title={<Typography variant='h6' color="common.black">{title}</Typography>}
                    subheader={new Date(date['seconds'] * 1000).toLocaleDateString('ru-RU')}
                />
                <CardMedia
                    sx={{ height: 200 }}
                    image={picture}
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom color="common.black" variant="h5" component="div">
                        {text}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small"><a href={link}>Читайте наш блог</a></Button>
                </CardActions>
            </Card>
        </Grid>
    })
    return (
        <React.Fragment>
            <Container>
                    <Typography variant="h2" component="h1">
                        Новости
                    </Typography>
                            <Box sx={{ bgcolor: '##559db8', minHeight: '100vh' }}>
                                <Grid container rowSpacing={2}>
                                        {
                                            renderNews
                                        }
                                </Grid>
                            </Box>
            </Container>
        </React.Fragment>
            )
        }