import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage"


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "inprof.firebaseapp.com",
    projectId: "inprof",
    storageBucket: "inprof.appspot.com",
    messagingSenderId: "769194605807",
    appId: "1:769194605807:web:77fbeac755eddb0833af1a"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app
export const db = getFirestore(app);
export const storage = getStorage(app)

