import {useState, useEffect} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../utils/firebase/firebase-utils";
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Typography
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";


export default function Organizers() {

    const [organizers, setOrganizers] = useState([])
    const organizerCollectionRef = collection(db, 'organizers')

    const getOrganizers = async() => {
        const data = await getDocs(organizerCollectionRef)
        // console.log(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        setOrganizers(data.docs.map((doc) => ({...doc.data(), id: doc.id})))

    }
    useEffect(() => {

        getOrganizers().then(data => {
            console.log('Organizers data fetched')
            // console.log(organizers)
        })
    }, [])
    return (
        <div className="organizers">
            <React.Fragment>
                <Container>
                    <Typography variant="h3" component="h2">
                        Организаторы
                    </Typography>
                    <Box sx={{ bgcolor: '##559db8', minHeight: '100vh' }}>
                            <Grid container rowSpacing={2}>
                                 {
                                        organizers.map((item) => (
                                            <Grid item key={item.id} xs={12} md={6} lg={4}>
                                                    <Card sx={{ maxWidth: 345 }} key={item.id}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                height="440"
                                                                image={item.logo}
                                                                alt="logo"
                                                            />
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" color="text.primary">
                                                                    {item.name}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                        <CardActions>
                                                            <Button size="small" onClick={() => window.location = `mailto:${item.email}`}>
                                                                Пишите организатору
                                                            </Button>
                                                            <Button size="small" onClick={() => window.location = `tel:${item.phoneNumber}`}>Позвонить организатору</Button>
                                                        </CardActions>
                                                    </Card>
                                            </Grid>
                                        ))
                                    }
                            </Grid>
                    </Box>
                </Container>
            </React.Fragment>
        </div>
    )
}