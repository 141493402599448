import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Schedule from "./pages/Schedule/Schedule";
import Participate from "./pages/Participate/Participate";
import Login from "./pages/Login/Login";
import RootLayout from "./Layout/RootLayout";
import Organizers from "./pages/Organizers/Organizers";
import HelpLayout from "./Layout/HelpLayout";
import FAQ from "./pages/Help/FAQ";
import ContactUs from "./pages/Help/ContactUs";
import NotFound from "./NotFound";
import {AuthContextProvider, useAuth} from "./context/AuthContext";
import Members from "./components/LichnyKabinet/ParticipantPage/Members";
import DownloadCase from "./components/LichnyKabinet/DownloadCase/DownloadCase";
import UploadCase from "./components/LichnyKabinet/UploadCase/UploadDocument";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import ChatPage from "./components/LichnyKabinet/ChatPage/ChatPage";
import ChatBot from "./components/LichnyKabinet/ChatBot/ChatBot";
import Cabinet from "./components/LichnyKabinet/LichnyeKabinetLayout";
import ChatRoom from "./components/LichnyKabinet/ChatBot/ChatRoom";
import * as React from "react";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<RootLayout />}>
          <Route index element={<Home />}/>
          <Route path='/o-nas' element={<Home />}/>
          <Route path='news' element={<News />}/>
          <Route path='organizers' element={<Organizers />}/>
          <Route path='graphic' element={<Schedule />}/>
          <Route path='register' element={<Participate />}/>
          <Route path='login' element={<Login />}/>
          <Route path='lk' element={<ProtectedRoutes><Cabinet /></ProtectedRoutes>}/>
          {/*<Route path='chatroom' element={<ChatRoom />}/>*/}
          {/*<Route path='download' element={<DownloadCase/>}/>*/}
          <Route path='download' element={<ProtectedRoutes><DownloadCase/></ProtectedRoutes>}/>
          <Route path='send' element={<ProtectedRoutes><UploadCase/> </ProtectedRoutes>}/>
          <Route path='members' element={<ProtectedRoutes><Members/> </ProtectedRoutes>}/>
            <Route path='contactus' element={<ProtectedRoutes><ChatPage/></ProtectedRoutes>}/>

          {/*<Route exact path='chatbot' element={<ChatBot/>}/>*/}
          <Route path='help' element={<HelpLayout/>}>
            <Route path='faq' element={<FAQ/>}/>
            <Route path='contact' element={<ContactUs/>}/>
          </Route>

          <Route path='*' element={<NotFound/>}/>
        </Route>
    )
)
function App() {

  return (
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
  );
}

export default App
