export default function FAQ() {
    return (
        <div className="faq">
            <h3>Часто задаваемые вопросы</h3>

            <div className="question">
                <p><strong>не могу войти в свой аккаунт:</strong></p>
                <p>Если ваш пароль и адрес электронной почты для входа неверны, попробуйте еще раз один или два раза. Это может случиться. Если нет, свяжитесь с разработчиком по адресу a_vrv@list.ru</p>
            </div>

            <div className="question">
                <p><strong>Не могу скачать кейс:</strong></p>
                <p>Связаться с разработчиком</p>
            </div>

            <div className="question">
                <p><strong>Я удалил свою работу и хотел бы знать, сохранили ли вы ее.</strong></p>
                <p>Мы не сохраняем удаленные работы. Так что будьте осторожны, удаляя свою работу.</p>
            </div>


        </div>
    )
}