import Box from "@mui/material/Box";
import LichnyKabinet from "./ParticipantPage/ParticipantPage";
import {Container, Grid} from "@mui/material";
import {FcDownload, FcSms, FcUpload} from "react-icons/fc";
import {TbReportAnalytics} from "react-icons/tb";
import {MdWifiCalling} from "react-icons/md";
import {RiLogoutBoxRFill} from "react-icons/ri";
import {NavLink} from "react-router-dom";
import React from "react";

export default function Cabinet(){
    const menus = [
        { name: "Скачать Кейс", link: "/download", icon: FcDownload },
        { name: "Отправить работу", link: "/send", icon: FcUpload },
        { name: "Участники и роли", link: "/members", icon: TbReportAnalytics, margin: true },
        { name: "Связаться с нами", link: "/contactus", icon: MdWifiCalling },
    ];
    return(
        <LichnyKabinet>
            <Grid container rowSpacing={2} columnSpacing={{xs: 2, sm: 2, md: 4}}>
                {
                    menus?.map((menu, i) => (
                        <Grid
                            key={i}
                            container
                            item xs={8}
                            md={6}
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <NavLink
                                to={menu?.link}
                            >
                                <Box
                                    margin='auto'
                                    display='flex'
                                    alignItems="center"
                                    justifyContent='center'
                                    bgcolor='#404040'
                                    borderRadius='12px'
                                    boxShadow='12'
                                    sx={{
                                        minWidth: "22vw",
                                        minHeight: "20vh",
                                        backgroundColor: "#6181a7"
                                    }}

                                >
                                    <div className={'text-red'}>{React.createElement(menu?.icon, { size: "75" })}</div>
                                </Box>
                            </NavLink>
                        </Grid>
                    ))
                }
            </Grid>
        </LichnyKabinet>
    )
}