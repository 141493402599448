import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import LichnyKabinet from "../ParticipantPage/ParticipantPage";
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../utils/firebase/firebase-utils";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ChatPage(){
    const [organizers, setOrganizers] = useState([])
    const organizerCollectionRef = collection(db, 'organizers')

    const getOrganizers = async() => {
        const data = await getDocs(organizerCollectionRef)
        // console.log(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        setOrganizers(data.docs.map((doc) => ({...doc.data(), id: doc.id})))

    }
    useEffect(() => {

        getOrganizers().then(data => {
            console.log('Organizers data fetched')
            // console.log(organizers)
        })
    }, [])
    // console.log("organizers", organizers)
    return(
        <LichnyKabinet>
            <React.Fragment>
                <Container>
                    <Box sx={{ bgcolor: '##559db8', minHeight: '100vh' }}>
                        <Grid container rowSpacing={2}>
                            {
                                organizers.map((item) => (
                                    <Grid item key={item.id} xs={12} md={6} lg={4}>
                                        <Card sx={{ maxWidth: 250 }} key={item.id}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="440"
                                                    image={item.logo}
                                                    alt="logo"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="p" color="text.primary">
                                                        {item.name}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button size="small" onClick={() => window.location = `mailto:${item.email}`}>
                                                    Пишите организатору
                                                </Button>
                                                <Button size="small" onClick={() => window.location = `tel:${item.phoneNumber}`}>Позвонить организатору</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Container>
            </React.Fragment>
        </LichnyKabinet>
    )
}